@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base RTL styles */
[dir="rtl"] {
  text-align: right;
}

body {
  font-family: 'Inter', sans-serif;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

/* Utility classes for RTL/LTR layouts */
@layer utilities {
  .flip-rtl {
    @apply rtl:-scale-x-100;
  }
  
  .rotate-rtl {
    @apply rtl:rotate-180;
  }
  
  .text-start {
    @apply ltr:text-left rtl:text-right;
  }
  
  .text-end {
    @apply ltr:text-right rtl:text-left;
  }
}

/* Custom RTL modifications */
[dir="rtl"] .font-poppins {
  font-family: 'IBM Plex Sans Arabic', 'system-ui', sans-serif;
}

/* Animation fixes for RTL */
[dir="rtl"] .animate-fade-in-down {
  animation-direction: reverse;
}